<template>
  <a v-if="!localUrl || localUrl.indexOf(' ') >= 0" href="#">
    <slot></slot>
  </a>
  <a v-else-if="localUrl.indexOf('whizliz.com') < 0" :href="localUrl" target="_blank">
    <slot></slot>
  </a>
  <router-link v-else :to="localUrl.split('whizliz.com')[1]">
    <slot></slot>
  </router-link>
</template>
<script>
export default {
  name: "UrlHandler",
  props: ["url"],
  data() {
    return {
      localUrl: this.url,
    }
  },
  mounted() {
    this.localUrl = this.handleUrl(this.url)
  },
  methods: {
    handleUrl(url) {
      if (url && url.indexOf('https://') != 0) url = 'https://' + url
      return url
    }
  },
  // watch: {
  //   url(newVal) {
  //     console.log('kena watch');
  //     this.localUrl = this.handleUrl(newVal)
  //   }
  // }
}
</script>
